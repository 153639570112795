<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" class="d-flex flex-column justify-space-between align-center">
        <v-img width="200" max-width="200" src="../assets/4cast-logo-white.svg"></v-img>

        <h1 class="white--text">4CAST</h1>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-btn min-width="100%" color="tertiary" @click="about">About</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!user" class="text-center">
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-btn min-width="100%" color="primary" @click="login">Login</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!user" class="text-center">
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-btn min-width="100%" color="primary" @click="signUp">Sign Up</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="user" class="text-center">
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-btn min-width="100%" color="primary" @click="goToLogs">Go To Dashboard</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  computed: {
    user() {
      return this.$store.state.user
    }
  },

  methods: {
    about() {
      this.$router.push('/about')
    },
    login() {
      this.$router.push('/login')
    },
    signUp() {
      this.$router.push('/signup')
    },
    goToLogs() {
      this.$router.push('/logs')
    }
  }
}
</script>

<style lang="scss">
  .home-header {
    color: white;
  }
</style>
