<template>
  <div>
    <v-alert
      v-if="!hasAgreedToCookies"
      id="cookies"
      border="top"
      color="primary"
    >
      <span class="white--text">We use cookies to improve your experience.</span>
      <v-btn id="cookies-agree" color="white" @click="agree">
        Agree & Close
      </v-btn>
    </v-alert>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'Cookies',
    computed: {
      ...mapState(['hasAgreedToCookies'])
    },
    methods: {
      ...mapActions(['agreeToCookies']),
      agree() {
        this.agreeToCookies(true)
      }
    }
  }
</script>

<style lang="scss">
#cookies {
  position: fixed;
  width: 100%;
  z-index: 9999999999 !important;
}

#cookies-agree {
  margin-left: 1rem;
}
</style>
