import Tide from './tide'
import Swell from './swell'
import Wind from './wind'

export default class Forecast {
  constructor(options = {}) {
    const {
      unit,
      time,
      swell,
      wind,
      tide
    } = options

    this.unit = unit || null
    this.time = time || null
    this.swell = new Swell(swell)
    this.wind = new Wind(wind)
    this.tide = []

    try {
      tide.forEach(t => {
        this.tide.push(new Tide(t))
      })
    } catch (e) {
      // Ignore errors.
    }
  }
}
