<template>
  <v-container>
   <v-row class="text-center">
    <v-col cols="12" class="d-flex flex-column justify-space-between align-center">
      <v-img width="100" max-width="100" src="../assets/4cast-logo-white.svg"></v-img>
      <h1 class="home-header">4CAST</h1>
    </v-col>
    </v-row>
    <v-row>
      <LoginForm />
    </v-row>
    <v-row>
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-btn color="tertiary" width="100%" @click="signUp">Create an account instead</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-btn color="tertiary" width="100%" @click="goHome">Back Home</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import LoginForm from '@/components/LoginForm.vue'

export default {
  name: 'Home',
  components: {
    LoginForm
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },

  methods: {
    goHome() {
      this.$router.push('/')
    },
    signUp() {
      this.$router.push('/signup')
    }
  }
}
</script>
