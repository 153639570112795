<template>
  <v-card-text>
    <v-row class="text-center">
      <LogItemSquare
        :logProperty="log.forecast.swell.height"
        logPropertyUnit="m"
        icon="mdi-human-male-height-variant"
        logPropertyName="Swell Height"
      />

      <LogItemSquare
        :logProperty="log.forecast.swell.period"
        logPropertyUnit="s"
        icon="mdi-cosine-wave"
        logPropertyName="Swell Period"
      />
    </v-row>

    <v-row class="text-center">
      <LogItemSquare
        :logProperty="log.forecast.wind.speed"
        logPropertyUnit="km/hr"
        icon="mdi-weather-windy-variant"
        logPropertyName="Wind"
      />

      <LogItemSquare
        :logProperty="log.forecast.wind.state.display"
        icon="mdi-windsock"
        logPropertyName="State"
      />
    </v-row>
  </v-card-text>
</template>

<script>
  import LogItemSquare from '@/components/LogItemSquare.vue'

  export default {
    name: 'LogItemMain',

    props: ['log'],

    components: {
      LogItemSquare
    }
  }
</script>
