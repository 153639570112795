import Forecast from './forecast'

export default class Log {
  constructor(options = {}) {
    const {
      _id,
      notes,
      location,
      rating,
      date,
      unit,
      board,
      forecast,
    } = options

    this._id = _id || null
    this.notes = notes || null
    this.location = location || null
    this.rating = rating || null
    this.unit = unit || null
    this.date = null
    this.board = board || null

    this.setDate(date)

    this.forecast = new Forecast(forecast || {})

    this.defaultForecastTimeIfNew()
  }

  setDate(date) {
    // Default the date to now if it's a new entry.
    if (!this._id && !date) {
      this.date = new Date().toISOString().substr(0, 10)
    }

    if (date) {
      this.date = new Date(parseInt(date)).toISOString().substr(0, 10)
    }
  }

  defaultForecastTimeIfNew() {
    if (!this._id && !this.forecast.time) {
      const now = new Date()
      this.forecast.time = `${now.getHours()}:${now.getMinutes()}`
    }
  }

  reset() {
    this.id = null
    this.notes = null
  }
}
