export default {
  login (state, payload) {
    const { user } = payload
    state.user = user
  },
  logout (state) {
    state.user = null
  },
  setAuthToken (state, payload) {
    state.authToken = payload
  },
  setLoginError (state, payload) {
    state.loginError = payload
  },
  setSignUpError (state, payload) {
    state.signUpError = payload
  },
  setCookies (state, payload) {
    state.hasAgreedToCookies = payload
  },
  setContactFormError(state, payload) {
    state.contactFormError = payload
  }
}
