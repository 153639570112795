<template>
  <v-card-text>
    <v-row>
      <LogItemSquare
        :logProperty="log.forecast.swell.direction"
        icon="mdi-navigation"
        logPropertyName="Swell Direction"
      />
      <LogItemSquare
        :logProperty="log.forecast.wind.direction"
        icon="mdi-compass"
        logPropertyName="Wind Direction"
      />
    </v-row>

    <v-row>
      <LogItemSquare
        :logProperty="log.board"
        icon="mdi-surfing"
        logPropertyName="Board"
      />
    </v-row>
    
    <v-row class="text-center">
      <v-col v-if="log.notes" cols="12" class="text-left">
        <p class="black--text"><strong>Notes:</strong> {{ log.notes }}</p>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
  import LogItemSquare from '@/components/LogItemSquare.vue'

  export default {
    name: 'LogItemExpanded',

    props: ['log'],

    components: {
      LogItemSquare
    }
  }
</script>
