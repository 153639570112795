<template>
  <v-footer class="main-footer" color="tertiary" :app="app" :fixed="false" :class="{ 'main-footer-bottom-padding': shouldPad }">
    <v-container>
      <v-row align="center">
        <v-col align="center">
          <v-img width="50" max-width="50" src="../assets/4cast-logo-white.svg"></v-img>
          <p class="white--text">&copy; {{ currentYear }}</p>
          <p>
            <router-link to="Privacy">Privacy Policy</router-link> |
            <router-link to="Contact">Contact</router-link>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer',
    props: ['app'],
    computed: {
      currentYear() {
        return new Date().toISOString().substring(0,4)
      },
      shouldPad() {
        if (!this.$route.name) return false

        const nonPaddedRoutes = ['Home', 'Login', 'SignUp', 'About']

        if (nonPaddedRoutes.includes(this.$route.name)) {
          return false
        }

        return true
      }
    }
  }
</script>

<style scoped>
  .main-footer {
    position: relative !important;
  }

  .main-footer-bottom-padding {
    margin-bottom: 2.5rem;
  }
</style>
