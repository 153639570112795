class WindState {
  constructor(options = {}) {
    const { value, display } = options

    this.value = value || null
    this.display = display || null
  }
}

export default class Wind {
  constructor(options = {}) {
    const { gusts, direction, speed, state } = options

    this.state = new WindState(state || {})
    this.gusts = gusts || null
    this.direction = direction || null
    this.speed = speed || null
  }
}
