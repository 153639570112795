<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex flex-column justify-space-between align-center">
        <v-img width="200" max-width="200" src="../assets/4cast-logo-white.svg"></v-img>

        <h1 class="white--text">4CAST</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card color="white">
          <v-row>
            <v-col align="center" cols="12">
              <p>
                4CAST is an app that helps you track the surf forecast by manually logging it.
              </p>

              <v-divider></v-divider>
              <br>

              <h4 class="primary--text">All your logs in one place</h4>

              <v-img justify="center" width="300" class="center" src="../assets/screenshots/screenshot-logs.jpg"></v-img>


              <v-divider></v-divider>
              <br>

              <h4 class="primary--text">Track the swell, wind, board you used, add notes and rate the day</h4>

              <v-img justify="center" width="300" class="center" src="../assets/screenshots/screenshot-add.jpg"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>


    <v-row v-if="!user" class="text-center">
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-btn min-width="100%" color="primary" @click="signUp">Sign Up</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="user" class="text-center">
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-btn min-width="100%" color="primary" @click="goToLogs">Go To Dashboard</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-btn color="tertiary" width="100%" @click="goHome">Back Home</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'About',
  computed: {
    user() {
      return this.$store.state.user
    }
  },

  methods: {
    goHome() {
      this.$router.push('/')
    },
    signUp() {
      this.$router.push('/signup')
    },
    goToLogs() {
      this.$router.push('/logs')
    }
  }
}
</script>
