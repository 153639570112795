<template>
  <div class="home">
    <LogForm :log="log" :mode="mode" />
  </div>
</template>

<script>
// @ is an alias to /src
import LogForm from '@/components/LogForm.vue'
import Log from '../models/log'

export default {
  name: 'AddLog',
  components: {
    LogForm
  },
  data: () => ({
    log: new Log(),
    mode: 'Add a New'
  })
}
</script>
