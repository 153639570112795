<template>
  <v-bottom-navigation
    class="padding-top"
    value="currentRoute"
    grow
    background-color="white"
    color="primary"
    fixed
  >
    <v-btn :to="'/logs'" value="Logs">
      <v-icon>mdi-post</v-icon>
    </v-btn>

    <v-btn :to="'/add'" value="AddLog">
      <v-icon>mdi-plus-circle</v-icon>
    </v-btn>

    <v-btn :to="'/coffee'" value="Coffee">
      <v-icon>mdi-coffee</v-icon>
    </v-btn>

    <v-btn value="logout" @click="onLogout">
      <v-icon>mdi-power</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { onLogout } from '../vue-apollo'

  export default {
    name: 'Navigation',

    props: ['app'],

    data: () => ({
      value: 1,
      backgroundColor: 'rgba(250,250,250,0.94)',
    }),

    computed: {
      ...mapState(['user']),
      currentRoute() {
        return this.$route.name
      }
    },

    methods: {
      ...mapActions(['logout', 'login']),
      onLogout() {
        this.logout()
        onLogout(this.$apollo.provider.defaultClient)

        if (this.$route.name && this.$route.name.includes('Home')) {
          // Don't re-route if we're already home.
          return
        }

        this.$router.push('/')
      }
    }
  }
</script>

<style scoped lang="scss">
  .padding-top {
    padding-top: 10px;
  }
</style>
