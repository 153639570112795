import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        primary: colors.pink.lighten1,
        secondary: colors.pink.lighten4,
        tertiary: colors.cyan.lighten2,
        accent: colors.cyan.base,
      },
    },
  },
});
