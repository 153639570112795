import api from '../api'

export default {
  agreeToCookies({ commit }, playload) {
    commit('setCookies', playload)
    localStorage.setItem('hasAgreedToCookies', true)
  },

  login ({ commit }, payload) {
    return api.login(payload)
      .then(response => {
        const { data } = response

        commit('login', data)
        commit('setAuthToken', data.token)
        commit('setLoginError', null)

        localStorage.setItem('slu', JSON.stringify(data.user))

        return data
      })
      .catch(error => {
        commit('setLoginError', error.message)
        localStorage.removeItem('slu')
      })
  },

  refreshToken({ commit }) {
    return api.refreshToken()
      .then(response => {
        const { data } = response

        commit('setAuthToken', data.token)

        return data
      })
  },

  removeAuthToken({ commit }) {
    commit('setAuthToken', null)
  },

  logout ({ commit, state }) {
    return api.logout({ token: state.authToken })
      .finally(() => {
        commit('logout')
        commit('setAuthToken', null)
        localStorage.removeItem('slu')
      })
  },

  signup({ commit }, payload) {
    return api.register(payload)
      .then(response => {
        const { data } = response

        commit('login', data)
        commit('setSignUpError', null)

        localStorage.setItem('slu', JSON.stringify(data.user))

        return data
      })
      .catch(error => {
        commit('setSignUpError', error.response.data.message)
      })
  },

  setLoginError({ commit }) {
    commit('setLoginError', null)
  },

  setSignUpError({ commit }) {
    commit('setSignUpError', null)
  },

  submitContactForm({ commit }, payload) {
    console.log('action callinga pi...')
    return api.contact(payload)
      .then(data => {
        commit('setContactFormError', null)
        return data
      })
  },

  setContactFormError({ commit }, payload) {
    commit('setContactFormError', payload)
  },
}
