<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h1 class="display-2 font-weight-bold mb-3">
          My Account
        </h1>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <p>Do stuff to your account here.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Account',

    data: () => ({
      ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
    }),
  }
</script>
