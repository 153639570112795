<template>
  <v-card
    class="mx-auto text-center"
    :raised="true"
    :ripple="true"
    :color="cardColor"
  >
    <v-card-text class="pb-0">
      <v-row>
        <v-col cols="12">
          <p>{{ message }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4"></v-col>
        <v-col cols="12" sm="4">
          <v-btn color="primary" width="100%" @click="addLog">Add Your First Log</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'NoLogsMessage',

    props: ['message'],

    data: () => ({
      cardColor: 'rgba(250,250,250,0.94)',
    }),

    methods: {
      addLog() {
        this.$router.push('/add')
      }
    }
  }
</script>
