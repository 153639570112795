<template>
  <div>
    <ApolloQuery
      :query="require('../graphql/Log.gql')"
      :variables="{ id: logId }"
      :update="onUpdate"
    >
      <div slot-scope="{ result: { data, loading, error } }">
        <template v-if="data">
          <div v-if="loading"><p>Loading...</p></div>
          <div v-if="error"><p>There was an error</p></div>
          <div
            v-else
          >
            <LogForm :log="data" :mode="mode" />
          </div>
        </template>
      </div>
    </ApolloQuery>
  </div>
</template>

<script>
// @ is an alias to /src
import LogForm from '@/components/LogForm.vue'
import Log from '@/models/log'

export default {
  name: 'EditLog',
  components: {
    LogForm
  },
  data: () => ({
    mode: 'Editing'
  }),
  computed: {
    logId() {
      return this.$route.params.logId
    }
  },
  methods: {
    onUpdate(data) {
      return new Log(data.log)
    }
  }
}
</script>
