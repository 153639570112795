<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <p v-if="signUpError">{{ signUpError }}</p>

        <v-form
          ref="form"
        >
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Email"
            required
          ></v-text-field>

          <v-text-field
            v-model="password"
            type="password"
            :rules="passwordRules"
            label="Password"
            required
          ></v-text-field>

          <v-text-field
            v-model="confirmPassword"
            type="password"
            :rules="confirmPasswordRules"
            label="Confirm Password"
            required
          ></v-text-field>

          <p><strong>By signing up, you agree to our  <router-link to="Privacy">terms of use</router-link>.</strong></p>

          <v-btn
            color="primary"
            class="mr-4"
            @click="submit"
            width="100%"
          >
            Sign Me Up
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { onLogin } from '../vue-apollo'

  export default {
    name: 'SignUpForm',

    data() {
      return {
        submitted: false,
        email: null,
        emailRules: [
          v => !!v || 'Email is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        password: null,
        passwordRules: [
          v => !!v || 'Password is required',
        ],
        confirmPassword: null,
        confirmPasswordRules: [
          v => !!v || 'You must confirm your password',
          (value) => value === this.password || 'Passwords must match'
        ]
      }
    },

    computed: {
      ...mapState(['signUpError', 'user'])
    },

    methods: {
      ...mapActions(['signup', 'setSignUpError']),
      validate() {
        return this.$refs.form.validate()
      },
      submit() {
        const isValid = this.validate()

        if (!isValid) return

        this.submitted = true
        this.setSignUpError(null)

        const { email, password } = this

        this.signup({ username: email, password })
          .then(data => {
            // Go to Logs if signup was a success.
            if (this.user) {
              onLogin(this.$apollo.provider.defaultClient, data.token)
              this.$router.push('Logs')
            }
          })
      }
    }
  }
</script>
