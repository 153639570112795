import Vue from 'vue'
import Vuex from 'vuex'

import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

let user = null

const userString = localStorage.getItem('slu')
if (userString) {
  try {
    user = JSON.parse(userString)
  } catch (e) {
    // Do nothing.
  }
}

const hasAgreedToCookies = localStorage.getItem('hasAgreedToCookies') === 'true'

const store = new Vuex.Store({
  state: {
    hasAgreedToCookies,
    user,
    authToken: null,
    loginError: null,
    signUpError: null,
    contactFormError: null
  },
  mutations,
  actions
})

export default store
