<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <p v-if="loginError">Oops, that wasn't the response we were expecting.</p>

        <v-form
          ref="form"
          @submit="submit"
        >
          <v-text-field
            color="primary"
            :filled="true"
            v-model="email"
            :rules="emailRules"
            label="Email"
            required
          ></v-text-field>

          <v-text-field
            color="primary"
            :filled="true"
            v-model="password"
            type="password"
            :rules="passwordRules"
            label="Password"
          ></v-text-field>

          <v-btn
            type="submit"
            color="primary"
            class="mr-4"
            @click="submit"
            width="100%"
          >
            Login
          </v-btn>

        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { onLogin } from '../vue-apollo'

  export default {
    name: 'LoginForm',

    data: () => ({
      filled: true,
      submitted: false,
      email: null,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      password: null,
      passwordRules: [
        v => !!v || 'Password is required'
      ]
    }),

    computed: {
      ...mapState(['loginError', 'user'])
    },

    methods: {
      validate() {
        return this.$refs.form.validate()
      },
      ...mapActions(['login', 'setLoginError']),
      submit(event) {
        event.preventDefault()
        const isValid = this.validate()

        if (!isValid) return

        this.submitted = true
        this.setLoginError(null)

        const { email, password } = this

        this.login({ username: email.toLowerCase().trim(), password })
          .then(data => {
            // Go to Logs if login was a success.
            if (this.user) {
              onLogin(this.$apollo.provider.defaultClient, data.token)
              this.$router.push('Logs')
            }
          })
      }
    }
  }
</script>
