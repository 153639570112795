import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/privacy',
    name: 'Privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logs',
    name: 'Logs',
    component: () => import(/* webpackChunkName: "logs" */ '../views/Logs.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add',
    name: 'Add Log',
    component: () => import(/* webpackChunkName: "addLog" */ '../views/AddLog.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/edit/:logId',
    name: 'Edit Log',
    component: () => import(/* webpackChunkName: "editLog" */ '../views/EditLog.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/coffee',
    name: 'Coffee',
    component: () => import(/* webpackChunkName: "coffee" */ '../views/Coffee.vue'),
    meta: {
      requiresAuth: false
    }
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = localStorage.getItem('slu')
    if (!user) {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
