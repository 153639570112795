<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- Logs List -->
         <ApolloQuery
          :query="require('../graphql/Logs.gql')"
          :update="groupAndSort"
        >
          <ApolloSubscribeToMore
            :document="require('../graphql/LogAdded.gql')"
          />

          <v-col cols="12" slot-scope="{ result: { data, loading } }">
            <v-row v-if="loading">
              <v-col cols="12">
                <Message :message="loadingMessage" />
              </v-col>
            </v-row>
            <div v-if="data && data.dates">
              <v-row
                v-for="date of data.dates"
                :key="date"
              >
                <v-col cols="12">
                  <h4 class="white--text" color="tertiary">{{ formatDate(date) }}</h4>
                </v-col>

                <v-row>
                  <LogItem
                    v-for="log of data.groupedByDate[date]"
                    :key="log._id"
                    :log="log" 
                  ></LogItem>
                </v-row>
              </v-row>
            </div>
            <v-row v-if="!data || data.dates.length < 1">
              <v-col cols="12">
                <NoLogsMessage :message="noLogsMessage" />
              </v-col>
            </v-row>
          </v-col>
        </ApolloQuery>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import LOGS from '../graphql/Logs.gql'
  import DELETE_LOG from '../graphql/DeleteLog.gql'
  import Log from '../models/log'
  import LogItem from '@/components/LogItem.vue'
  import NoLogsMessage from '@/components/NoLogsMessage.vue'

  export default {
    name: 'Logs',

    components: {
      LogItem,
      NoLogsMessage
    },

    data: () => ({
      loadingMessage: 'Loading...',
      noLogsMessage: 'No logs yet.'
    }),

    computed: {
      ...mapState(['authToken'])
    },

    methods: {
      formatDate(string) {
        const dateParts = new Date(Number(string)).toISOString().substring(0,10).split('-')

        const year = dateParts[0]
        const month = Number(dateParts[1])
        const date = Number(dateParts[2])

        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

        return `${months[month - 1]} ${date}, ${year}`
      },
      groupAndSort(data) {
        const groupedByDate = {}

        // TODO - if no dates, show message to add a log.
        if (!data.userLogs) return { dates: [], groupedByDate }

        data.userLogs.forEach(log => {
          groupedByDate[log.date] = groupedByDate[log.date] || []
          groupedByDate[log.date].push(new Log(log))
        })

        const dates = Object.keys(groupedByDate).sort((a, b) => b - a)

        dates.forEach(date => {
          groupedByDate[date] = groupedByDate[date].sort((a, b) => {
            if (a.time && b.time) {
              return a.time - b.time
            }

            return true
          })
        })

        return { dates, groupedByDate }
      },
      editLog(logId) {
        this.$router.push({ name: 'Edit Log', params: { logId } })
      },
      async deleteLog(logId) {
        await this.$apollo.mutate({
          mutation: DELETE_LOG,
          variables: {
            id: logId
          },
          update: (store, { data: { deleteLog } }) => {
            const data = store.readQuery({ query: LOGS })

            if (deleteLog.success) {
              const filtered = data.userLogs.filter(log => log._id !== logId)
              data.userLogs = [...filtered]

              store.writeQuery({ query: LOGS, data })
            }

            // Otherwise - flash error?
          }
        })
      }
    }
  }
</script>
