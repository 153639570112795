<template>
  <v-col cols="12" sm="6" md="4" lg="3">
    <v-card
      class="mx-auto"
      :raised="true"
      :ripple="true"
      :color="cardColor"
      @click="show = !show"
    >
      <v-card-title>
        <v-row no-gutters>
          <v-col cols="6" class="text-left">
            <v-icon color="primary" v-for="n in log.rating" :key="n">mdi-star</v-icon>
            <span v-if="!log.rating">No rating.</span>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-subtitle v-if="log.location">
        <v-row no-gutters>
          <v-col cols="6" class="text-left" no-gutters>
            <p class="no-margin black--text">
              <v-icon color="black">mdi-map-marker</v-icon>
              <span v-if="log.location">{{ log.location }}</span>
              <span v-else>No location.</span>
            </p>
          </v-col>
          <v-col cols="6" class="text-right" no-gutters>
            <p class="no-margin black--text">
               <v-icon color="black">mdi-clock</v-icon>
              <span v-if="log.forecast.time">{{ log.forecast.time }}</span>
              <span v-else>No time.</span>
            </p>
          </v-col>
        </v-row>
      </v-card-subtitle>

      <v-divider></v-divider>

      <LogItemMain :log="log" />

      <v-spacer></v-spacer>

      <v-divider></v-divider>

      <!-- Expanded content. -->
      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
          <LogItemExpanded :log="log" />

          <v-divider></v-divider>
          <v-card-text>
            <v-row class="text-center">
              <v-col cols="12" class="text-center">
                <v-btn color="primary" @click="editLog()">
                  <v-icon>mdi-pencil</v-icon> Edit
                </v-btn>
                <v-btn color="primary" @click="deleteLog()">
                  <v-icon>mdi-delete</v-icon> Delete
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </v-col>
</template>

<script>
  import LOGS from '../graphql/Logs.gql'
  import DELETE_LOG from '../graphql/DeleteLog.gql'
  import LogItemMain from '@/components/LogItemMain.vue'
  import LogItemExpanded from '@/components/LogItemExpanded.vue'

  export default {
    name: 'LogItem',

    props: ['log'],

    components: {
      LogItemMain,
      LogItemExpanded
    },

    data: () => ({
      cardColor: 'rgba(250,250,250,0.94)',
      show: false
    }),

    methods: {
      editLog() {
        this.$router.push({ name: 'Edit Log', params: { logId: this.log._id } })
      },
      async deleteLog() {
        await this.$apollo.mutate({
          mutation: DELETE_LOG,
          variables: {
            id: this.log._id
          },
          update: (store, { data: { deleteLog } }) => {
            const data = store.readQuery({ query: LOGS })

            if (deleteLog.success) {
              const filtered = data.userLogs.filter(log => log._id !== this.log._id)
              data.userLogs = [...filtered]

              store.writeQuery({ query: LOGS, data })
            }

            // Otherwise - flash error?
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
.no-margin {
  margin: 0 !important;
}
</style>

