<template>
  <v-col cols="6">
    <h2 class="black--text">
      <span v-if="!logProperty">
        --
      </span>
      <span v-else>
        {{ logProperty }}{{logPropertyUnit || ''}}
      </span>
    </h2>
    <p class="black--text">
      <v-icon>{{ icon }}</v-icon> {{ logPropertyName }}
    </p>
  </v-col>
</template>

<script>
  export default {
    name: 'LogItemSquare',

    props: ['logProperty', 'logPropertyName', 'logPropertyUnit', 'icon'],
  }
</script>
