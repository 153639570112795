<template>
  <v-container>
    <v-row class="text-center" v-if="!secret && !completed">
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <p v-if="error" class="red--text"><strong>{{ error }}</strong></p>

        <v-form
          ref="form"
        >
          <v-text-field
            v-model="name"
            :rules="nameRules"
            label="Cardholder Name"
            required
          ></v-text-field>

          <v-currency-field
            label="Amount"
            :error-messages="amountRules"
            filled
            v-model="amount"
          />

          <v-radio-group label="Currency" v-model="currency">
            <v-radio
              v-for="n in currencies"
              :key="n.value"
              :label="n.label"
              :value="n.value"
              row
            ></v-radio>
          </v-radio-group>

          <v-spacer></v-spacer>
          <br><br>

          <v-btn
            color="primary"
            class="mr-4"
            @click="submit"
            width="100%"
            :disabled="!name || loading"
          >
            {{ loading ? 'Submitting...' : 'Next' }}
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <v-row v-show="secret && !completed">
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <div id="card-element"></div>
        <br><br>
        <v-btn
            color="primary"
            class="mr-4"
            @click="submitPayment"
            width="100%"
            id="card-button"
            :disabled="!!error || loading"
          >
            {{ loading ? 'Submitting...' : 'Buy me a coffee' }}
          </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="completed">
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4" align="center">
        <p>Thank you so much! Anything and everything is appreciated! Cheers :)</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState, } from 'vuex'
  import CREATE_PAYMENT_INTENT from '../graphql/CreatePaymentIntent.gql'

  export default {
    name: 'PaymentForm',

    data() {
      return {
        $stripe: null,

        loading: false,
        submitted: false,

        token: null,
        card: null,

        currency: 'usd',
        secret: null,

        name: null,
        nameRules: [
          v => !!v || 'Name is required',
          v => !!v && v.length > 0 || 'Please enter a valid name',
        ],
        amount: 500,
        amountRules: [
          v => {
            if (!v) return true

            try {
              const int = Number(v.replace(/,/g, ''))
              return int > 0 || 'The amount must be greater than 0.'
            } catch (e) {
              return 'Invalid input'
            }
          }
        ],

        currencies: [
          { value: 'usd', label: '$' },
          { value: 'eur', label: '€' },
        ],

        error: null,
        completed: false
      }
    },

    computed: {
      ...mapState(['user'])
    },

    beforeDestroy () {
      if (this.card) {
        this.card.destroy()
      }
    },

    mounted() {
      this.$stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK)

      this.mountStripe()
    },

    methods: {
      validate() {
        return this.$refs.form.validate()
      },
      async submit() {
        // https://stripe.com/docs/accepting-donations
        const isValid = this.validate()

        if (!isValid) return

        this.submitted = true
        this.loading = true

        try {
          await this.$apollo.mutate({
            mutation: CREATE_PAYMENT_INTENT,
            variables: {
              data: {
                amount: this.amount,
                name: this.name,
                currency: this.currency
              }
            },
            update: (store, { data: { createPaymentIntent } }) => {
              this.loading = false
              const secret = createPaymentIntent.client_secret

              this.secret = secret

              if (!secret) {
                this.error = 'There was an error, please contact support.'
              }
            }
          })
        } catch (error) {
          this.error = 'There was an error, please contact support.'
        }
      },

      mountStripe() {
        const elements = this.$stripe.elements()

        this.card = elements.create('card', {})
        this.card.mount('#card-element')

        this.card.addEventListener('change', (event) => {
          if (event.error) {
            this.error = event.error.message;
          } else {
            this.error = null;
          }
        });
      },

      submitPayment() {
        if (!this.secret) {
          this.error = 'Something went wrong, please contact support.'
          return
        }

        this.loading = true

        return this.$stripe.confirmCardPayment(this.secret, {
            payment_method: {
              card: this.card,
              billing_details: { name: this.name, email: this.user.email },
            }
          })
          .then(() => {
            this.loading = false
            this.completed = true
          })
          .catch(() => {
            this.loading = false
            this.error = 'Something went wrong, please contact support.'
          })
      }
    }
  }
</script>

<style scoped>
  .selected-currency {
    color: black;
  }
</style>
