<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row class="text-center">
          <v-col cols="12">
            <h1 class="display-2 font-weight-bold mb-3">
              Contact
            </h1>
          </v-col>
        </v-row>

        <v-row v-if="submitted && success">
          <v-col cols="12">
            <p>We've received your message, thank you for contacting us! We will get back to you shortly.</p>
          </v-col>
        </v-row>

        <v-row v-if="submitted && contactFormError">
          <v-col cols="12">
            <p>There was an error in submitting your message. Apologies while we look into this, please try again later, or email us at <a href="mailto:hello@4cast.app">hello@4cast.app</a></p>
          </v-col>
        </v-row>

        <v-row v-if="!success" class="text-center">
          <v-col cols="12">
            <p>Send us a message below, or alternatively, email us at <a href="mailto:hello@4cast.app">hello@4cast.app</a>.</p>
          </v-col>
          <v-col cols="12">
            <v-form
              ref="form"
            >
              <v-text-field
                v-if="!user"
                v-model="email"
                :rules="emailRules"
                label="Your Email"
                required
              ></v-text-field>

              <v-textarea
                v-model="message"
                :rules="messageRules"
                label="Message"
                required
              ></v-textarea>

              <v-btn
                :disabled="!message"
                @click="submit"
                color="primary"
              >Send</v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'Contact',

    data: () => ({
      email: null,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      message: null,
      messageRules: [],
      submitted: false,
      success: false
    }),

    computed: {
      ...mapState(['user', 'contactFormError'])
    },

    methods: {
      ...mapActions(['setContactFormError', 'submitContactForm']),
      validate() {
        return this.$refs.form.validate()
      },
      submit() {
        const formData = {
          email: this.email,
          message: this.message
        }

        const isValid = this.validate()

        if (!isValid) return

        this.setContactFormError(null)
        this.submitted = true
        this.success = false

        this.submitContactForm(formData)
          .then(() => {
            this.success = true
          })
          .catch(error => {
            this.setContactFormError(error.message)
            this.success = false
          })
      }
    },

    mounted() {
      if (this.user) {
        this.email = this.user.email
      }
    }

    // TODO - actually send via email.
    // TODO - use recaptcha?
  }
</script>
