<template>
  <v-container>
    <v-card color="white">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h1 class="display-2 font-weight-bold mb-3">
              <v-icon color="brown">mdi-coffee</v-icon>
            </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>Like the app? I accept coffee donations here.</p>
          </v-col>
        </v-row>
        <v-row>
          <PaymentForm />
        </v-row>

        <!-- <v-row>
          <v-col>
            <p>Got ideas about what could be improved?</p>

            <v-btn color="tertiary">Make a feature request</v-btn>
          </v-col>
        </v-row> -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import PaymentForm from '@/components/PaymentForm.vue';

  export default {
    name: 'Coffee',

    components: {
      PaymentForm
    },

    data: () => ({
    }),
  }
</script>
