<template>
  <v-app>
    <Cookies />
    <v-img class="home-background" v-if="isHome" src="./assets/large/waves2.jpg"></v-img>
    <v-img class="home-background" v-if="isLoginOrSignup" src="./assets/large/palm-trees.jpg"></v-img>
    <v-img class="home-background" v-if="!isLoginOrSignup && !isHome" src="./assets/large/waves-birdseye.jpg"></v-img>
    <v-main app>
      <router-view/>
    </v-main>
    <Navigation v-if="user && !isLoginOrSignup && !isHome" app />
    <Footer app />
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Cookies from './components/Cookies';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { onTokenRefreshed } from './vue-apollo';

export default {
  name: 'App',

  components: {
    Cookies,
    Navigation,
    Footer
  },

  data() {
    return {
      refreshTimeout: null
    }
  },

  computed: {
    ...mapState(['user', 'authToken']),
    isHome() {
      return this.$route.name && this.$route.name.includes('Home') || false
    },
    isLoginOrSignup() {
      return this.$route.name && (this.$route.name.includes('Login') || this.$route.name.includes('SignUp')) || false
    }
  },

  watch: {
    user(newValue) {
      if (newValue) {
        window.Sentry.setUser({ id: newValue._id, email: newValue.email })
      } else {
        window.Sentry.setUser(null)
      }
    },
    authToken(newValue) {
      // We were not able to get an auth token, log the user out and redirect to login
      if (!newValue) {
        this.logout()
        this.$router.push('Login')
      } else {
        // User just authenticated or token was just refreshed. Don't do any routing.
        onTokenRefreshed(this.$apollo.provider.defaultClient, newValue)
        this.setRefreshTimeout()
      }
    }
  },

  methods: {
    ...mapActions(['refreshToken', 'logout', 'removeAuthToken']),
    setRefreshTimeout() {
      if (this.refreshTimeout) {
        try {
          clearTimeout(this.refreshTimeout)
        } catch (error) {
          // Do nothing...
          console.debug(error.message)
        }
      }

      // Refresh the token in 14 minutes.
      this.refreshTimeout = setTimeout(() => {
        this.refreshToken()
          .catch(() => {
            this.removeAuthToken()
          })
      }, 1000 * 60 * 14)
    }
  },

  mounted() {
    // If we have a user from local storage, try to refresh their token.
    if (this.$store.state.user) {
      this.refreshToken()
        .then(() => {
          // Don't redirect if we're already on logs.
          if (this.$route.name && this.$route.name.includes('Logs')) {
            return
          }
          this.$router.push('Logs')
        })
        // Log them out if it failed.
        .catch(() => {
          this.logout()
          this.$router.push('Login')
        })
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.home-background {
  position: fixed !important;
  top: 0;
  z-index: 0;
  height: 100vh;
}
</style>
