<template>
  <v-container class="space-bottom">
    <v-card flat color="tertiary" @click="goToLogs">
      <v-container fluid>
        <v-btn icon>
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
        Cancel
      </v-container>
    </v-card>

    <v-card flat :color="cardColor">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-form ref="form">
              <v-rating
                v-model="log.rating"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half-full"
                hover
                length="5"
              ></v-rating>

              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="log.date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="log.date"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  id="date"
                  label="Date"
                  v-model="log.date"
                  flat
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="dateMenu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dateMenu.save(log.date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-menu
                ref="timeMenu"
                v-model="timeMenu"
                :close-on-content-click="false"
                :return-value.sync="log.forecast.time"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="log.forecast.time"
                    label="Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  id="time"
                  v-model="log.forecast.time"
                  class="mt-4"
                  format="24hr"
                  scrollable
                  min="00:00"
                  max="23:59"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="timeMenu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.timeMenu.save(log.forecast.time)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-menu>

              <v-combobox
                v-model="log.location"
                placeholder="Location"
                prepend-icon="mdi-map-marker"
                :items="locations"
              ></v-combobox>

              <!-- Forecast Start -->

              <v-text-field
                id="swellHeight"
                type="number"
                :min="0"
                :rules="minZeroRules"
                step="0.1"
                label="Swell Height"
                v-model.number="log.forecast.swell.height"
                placeholder="1.8"
                class="input"
                prepend-icon="mdi-human-male-height-variant"
              ></v-text-field>

              <v-text-field
                id="swellPeriod"
                type="number"
                :min="0"
                :rules="minZeroRules"
                step="1"
                label="Swell Period"
                v-model.number="log.forecast.swell.period"
                placeholder="14"
                class="input"
                prepend-icon="mdi-cosine-wave"
              ></v-text-field>

              <v-text-field
                id="windSpeed"
                type="number"
                :min="0"
                :rules="minZeroRules"
                step="1"
                label="Wind Speed"
                v-model.number="log.forecast.wind.speed"
                placeholder="12"
                class="input"
                prepend-icon="mdi-weather-windy-variant"
              ></v-text-field>

              <v-select
                v-model="log.forecast.wind.state"
                :items="windStates"
                item-text="display"
                item-value="value"
                label="Wind State"
                persistent-hint
                return-object
                single-line
                prepend-icon="mdi-windsock"
              ></v-select>

              <v-text-field
                id="windGusts"
                type="number"
                :min="0"
                :rules="minZeroRules"
                step="1"
                label="Wind Gusts"
                v-model.number="log.forecast.wind.gusts"
                placeholder="14"
                class="input"
                prepend-icon="mdi-weather-windy"
              ></v-text-field>

              <v-text-field
                id="swellDirection"
                label="Swell Direction"
                v-model="log.forecast.swell.direction"
                placeholder="25NW"
                class="input"
                prepend-icon="mdi-navigation"
              ></v-text-field>

              <v-text-field
                id="windDirection"
                label="Wind Direction"
                v-model="log.forecast.wind.direction"
                placeholder="170SW"
                class="input"
                prepend-icon="mdi-compass"
              ></v-text-field>

              <v-text-field
                id="board"
                label="Board"
                v-model="log.board"
                placeholder="Enter board used"
                class="input"
              ></v-text-field>

              <!-- Forecast End -->

              <v-textarea
                id="notes"
                label="Notes"
                v-model="log.notes"
                placeholder="Add some notes"
              ></v-textarea>

              <v-btn color="primary" class="mr-4" @click="saveLog()">Save</v-btn>
            </v-form>
            <p v-if="error">{{ error }}</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import Log from '../models/log'
  import CREATE_LOG from '../graphql/CreateLog.gql'
  import UPDATE_LOG from '../graphql/UpdateLog.gql'
  import LOGS from '../graphql/Logs.gql'

  export default {
    name: 'LogForm',

    props: {
      mode: String,
      log: Log
    },

    data: () => ({
      dateMenu: false,
      timeMenu: false,
      valid: false,
      time: null,
      submitted: false,
      error: null,
      ratingMin: 0,
      ratingMax: 5,
      minZeroRules: [
        v => {
          if (!v) return true

          return (v && v > 0) || 'The minimum is 0'
        }
      ],

      cardColor: 'rgba(250,250,250,0.94)',
      windStates: [
        { value: 'GLASS', display: 'Glass' },
        { value: 'ON', display: 'On' },
        { value: 'OFF', display: 'Off' },
        { value: 'CROSS', display: 'Cross' },
        { value: 'CROSS-OFF', display: 'Cross-Off' },
        { value: 'CROSS-ON', display: 'Cross-On' },
      ]
    }),

    computed: {
      ...mapState(['user']),
      locations() {
        return this.user.locations || ['Playa de Somo']
      }
    },

    methods: {
      saveLog() {
        // TODO - validate the form first!
        if (this.log._id) {
          return this.updateLog()
        }
        return this.addLog()
      },
      async addLog() {
        const { notes, date, board, location, rating, forecast } = this.log

        await this.$apollo.mutate({
          mutation: CREATE_LOG,
          variables: {
            logData: {
              notes,
              date,
              board,
              location,
              rating,
              forecast
            }
          },
          update: (store, { data: { createLog } }) => {

            const data = store.readQuery({ query: LOGS })

            data.userLogs.push(createLog)

            store.writeQuery({ query: LOGS, data })

            this.$router.push('/logs')
          }
        })
        .catch(error => this.error = error.message)
      },
      async updateLog() {
        const { _id, notes, date, board, location, rating, forecast } = this.log

        await this.$apollo.mutate({
          mutation: UPDATE_LOG,
          variables: {
            id: _id,
            update: {
              notes,
              date,
              board,
              location,
              rating,
              forecast
            }
          },
          update: (store, { data: { updateLog } }) => {

            const data = store.readQuery({ query: LOGS })

            const index = data.userLogs.findIndex(log => log._id === _id)

            data.userLogs[index] = updateLog

            store.writeQuery({ query: LOGS, data })

            this.$router.push('/logs')
          }
        })
        .catch(error => this.error = error.message)
      },
      goToLogs() {
        this.$router.push('/logs')
      }
    }
  }
</script>

<style scoped lang="scss">
.space-bottom {
  margin-bottom: 5rem;
}
</style>
