<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" class="d-flex flex-column justify-space-between align-center">
        <v-img width="200" max-width="200" src="../assets/4cast-logo-white.svg"></v-img>

        <h1 class="white--text">4CAST</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2 v-if="!user">Sign Up</h2>
        <br>

        <v-spacer></v-spacer>

        <p v-if="!user">We only use your information so that we can associate which logs belong to your account. We do not sell your information to any third parties. &#129305;</p>

        <p v-else>Looks like you are already logged in.</p>
      </v-col>
    </v-row>
    <v-row v-if="!user">
      <SignUpForm />
    </v-row>
    <v-row v-if="!user">
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-btn color="tertiary" width="100%" @click="goHome">Back Home</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="user" class="text-center">
      <v-col cols="12" sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-btn min-width="100%" color="primary" @click="goToLogs">Go To Dashboard</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import SignUpForm from '@/components/SignUpForm.vue'

export default {
  name: 'SignUp',
  components: {
    SignUpForm
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },

  methods: {
    goHome() {
      this.$router.push('/')
    },
    goToLogs() {
      this.$router.push('/logs')
    }
  }
}
</script>
