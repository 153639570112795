import axios from 'axios'
const ax = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3000',
  withCredentials: true
})

export default {
  register(data) {
    return ax.post('register', data)
  },

  contact(data) {
    return ax.post('contact', data)
  },

  login({ username, password }) {
    return ax.post('login', { username, password })
  },

  refreshToken() {
    return ax.post('token/refresh', null)
  },

  logout({ token }) {
    return ax.post('logout', null, { headers: { 'Authorization': `Bearer ${token}` } })
  }
}
