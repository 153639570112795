import Vue from 'vue';
import App from './App.vue';
import VueGtag from "vue-gtag";
import { createProvider } from './vue-apollo';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store/index';
import { VTextField } from 'vuetify/lib'  //Globally import VTextField

import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"

// Initialize Sentry
Sentry.init({
  Vue,
  dsn: "https://330b527409c647e6b697a354aa28687a@o569229.ingest.sentry.io/5714782",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})


Vue.component('v-text-field', VTextField)

import VCurrencyField from 'v-currency-field';

Vue.use(VCurrencyField, { 
  locale: 'en-US',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: true,
  allowNegative: false
});

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  // config: { id: 'G-XNX9B598Z5' }
  config: { id: 'UA-189717529-1' }
});

new Vue({
  apolloProvider: createProvider(),
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
